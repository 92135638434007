<template>
  <div class="works">
    <left-menu current="Income" />
    <div class="main">
      <el-row>
        <el-col :span="12">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item to="/income">我的收益</el-breadcrumb-item>
            <el-breadcrumb-item to="/incomeList">提现申请</el-breadcrumb-item>
            <el-breadcrumb-item>提现记录</el-breadcrumb-item>
          </el-breadcrumb>
        </el-col>
        <el-col :span="12" style="text-align: right"
          ><el-link type="primary" @click="$router.back()">
            &lt;返回</el-link
          ></el-col
        >
      </el-row>

      <el-divider></el-divider>
      <Title name="提现记录" />
      <div class="filterBox">
        <el-select
          size="small"
          @change="selectChange"
          v-model="filterValue"
          placeholder="请选择"
        >
          <el-option label="自定义" :value="1"></el-option>
          <el-option label="近三个月" :value="3"></el-option>
          <el-option label="近六个月" :value="6"></el-option>
          <el-option label="近一年" :value="12"></el-option>
        </el-select>
        <div class="picker" v-if="filterValue === 1">
          <el-date-picker
            size="small"
            v-model="pickerValue"
            type="daterange"
            value-format="yyyy-MM-dd"
            @change="datePickerChange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </div>
      </div>

      <el-table
        :data="tableData"
        :header-cell-style="headerStyle"
        style="width: 100%; margin-bottom: 48px; margin-top: 20px"
      >
        <el-table-column prop="cashOutDoneTime" label="时间" width="120">
          <template slot-scope="{ row }">
            <div class="btnBox">
              {{ $timestampToDate(row.insetTime) }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="typeString" label="交易名称" width="150" />
        <el-table-column prop="paymentCompany" label="付款方" />
        <el-table-column :formatter="money" prop="cashOutMoney" label="金额(税后)" width="130" />
        <el-table-column prop="status" label="提现状态" width="160">
          <template slot-scope="{ row }">
            <div class="btnBox">
              <span>{{ statusTxt(row.status) }}</span>
              <div class="warningBox">
                <el-tooltip
                  v-if="row.status == 2"
                  class="item"
                  effect="dark"
                  :content="row.desp"
                  placement="bottom"
                >
                  <img src="@/assets/warning.png" class="icon" />
                </el-tooltip>
                <el-link @click="goDetail(row.id)" style="color: #ff477e" type="info">详情</el-link>
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        v-if="total > 0"
        :current="params.pageNum"
        :total="total"
        :pageSize="params.pageSize"
        @change="onPageChange"
        @pageSizeChange="onPageSizeChange"
      />
    </div>
  </div>
</template>

<script>
import LeftMenu from "@/components/LeftMenu.vue";
import { cashOutRecord ,dictInfo} from "../api/index";
import Pagination from "../components/Pagination.vue";
import Title from "@/components/withdrawal/Title.vue";
const PAGESIZE = 10;
export default {
  components: { LeftMenu, Title, Pagination },
  data() {
    return {
      //列表数据
      tableData: [],
      //列表请求参数
      params: {
        endTime: "",
        pageNum: 1,
        pageSize: PAGESIZE,
        startTime: "",
        status: "",
      },
      //表头样式
      headerStyle: {
        background: "#F8F8F8",
        color: "#333",
        fontSize: "14px",
        fontWeight: "400",
        borderBottom: "none",
      },
      //下拉框默认展示近三个月
      filterValue: 3,
      //数据总条数
      total: 0,
      //时间段筛选的值
      pickerValue: []
    }
  },

  created() {
    this.params.startTime = this.formatDate(3)[0];
    this.params.endTime = this.formatDate(3)[1];
    dictInfo({ codes: ["10016"] })
      .then((res) => {
        this.typeData = res.data;
      })
      .finally(() => {
        this.getList();
      });
  },
  methods: {
    //查看详情
    goDetail(id) {
      this.$router.push('/withdrawalDetail/' + id)
    },
    //提现状态
    statusTxt(status) {
      let txt = "";
      switch (status) {
        case -1:
          txt = "已删除";
          break;
        case 0:
          txt = "提现中";
          break;
        case 1:
          txt = "提现成功";
          break;
        case 2:
          txt = "提现失败";
          break;
        default:
          break;
      }
      return txt;
    },

    //监听下拉款
    selectChange(e) {
      if (e !== 1) {
        this.pickerValue = [];
        this.params.startTime = this.formatDate(e)[0];
        this.params.endTime = this.formatDate(e)[1];
        this.getList();
      }
    },

    //获取提现记录
    getList() {
      cashOutRecord(this.params).then((res) => {
        let { total, list } = res.data;
        this.total = total;
        list.forEach((item) => {
            this.typeData.forEach((t) => {
              if (t.label == item.type) {
                item.typeString = t.value;
              }
            });
          });
        this.tableData = list;
      });
    },

    //近几月
    formatDate(month) {
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * month * 30);
      let startString = `${start.getFullYear()}-${
        start.getMonth() + 1
      }-${start.getDate()} ${start.getHours()}:${start.getMinutes()}:${start.getSeconds()}`;
      let endString = `${end.getFullYear()}-${
        end.getMonth() + 1
      }-${end.getDate()} ${end.getHours()}:${end.getMinutes()}:${end.getSeconds()}`;
      return [startString, endString];
    },

    onPageChange(e) {
      this.params.pageNum = e.current;
      this.getList();
    },

    onPageSizeChange(ps) {
      this.params.pageNum = 1;
      this.params.pageSize = ps;
      this.getList();
    },

    //选择时间段
    datePickerChange(e) {
      this.params.startTime = `${e[0]} 00:00:00`;
      let end = new Date();
      this.params.endTime = `${e[1]} ${end.getHours()}:${end.getMinutes()}:${end.getSeconds()}`;
      this.getList();
    },
        money(val){
          return val.cashOutMoney.toFixed(2);
      }
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/works.scss";
.main {
  box-sizing: border-box;
  ::v-deep .el-divider {
    background-color: #f4f4f4;
  }
  .btnBox {
    display: flex;
    align-items: center;
    .warningBox {
      display: flex;
      align-items: center;
      margin-left: 6px;
      .icon {
        width: 14px;
        height: 14px;
        margin-right: 6px;
        cursor: pointer;
      }
    }
  }

  .filterBox {
    display: flex;
    align-items: center;
    margin-top: 34px;
    .picker {
      margin-left: 16px;
    }
    ::v-deep .el-input__inner {
      border: 1px solid #ddd;
      padding-left: 14px;
      border-radius: 2px;
      color: #333;
    }
    ::v-deep .is-focus {
      .el-input__inner {
        border: 1px solid #ff004d;
        padding-left: 14px;
        border-radius: 2px;
        color: #333;
      }
    }
  }
}
</style>
