<template>
 <div class="name" :style="{'font-size':fontsize}">{{name}}</div>
</template>

<script>
export default {
  props:{
    name:{
      type:String,
      default:''
    },
    fontsize:{
      type:String,
      default:'24px'
    },
  },
  data () {
    return {
      
    }
  }
}
</script>

<style scoped lang="scss">
.name{
  color: #333333;
  font-weight: 500;
}
</style>